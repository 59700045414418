import React, { FunctionComponent, useState } from 'react';
import { useData } from './data/useData';
import GridBoxes from './components/GridBoxes/GridBoxes';
import { LostFoundContextProvider } from './helpers/lostFoundContext';
import { BOXES_LOAD_LIMIT } from './constants';
import styles from './LostFound.module.css';
import Loader from './components/Loader/Loader';

const LostFound: FunctionComponent = () => {
  const [offset, setOffset] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const { boxes, loading } = useData({ limit: BOXES_LOAD_LIMIT, offset });

  return (
    <LostFoundContextProvider>
      <GridBoxes
        boxes={boxes}
        isLoading={loading}
        className={isReady ? undefined : styles.wrapperNotReady}
        onNeedMoreBoxes={(): void => setOffset(offset + BOXES_LOAD_LIMIT)}
        onFirstBoxReady={(): void => setIsReady(true)}
      />
      {isReady ? undefined : <Loader />}
    </LostFoundContextProvider>
  );
};

export default LostFound;
