import React from 'react';

import styles from './Loader.module.css';

export default function Loader(): JSX.Element {
  return (
    <div className={styles.loader}>
      <div className={styles.ball}></div>
    </div>
  );
}
