import isImageUrl from './isImageUrl';
import isVideoUrl from './isVideoUrl';

export default async function preloadMedia(mediaSrc: string): Promise<void> {
  if (isImageUrl(mediaSrc)) {
    const media = new Image();
    return new Promise<void>((resolve) => {
      media.addEventListener('load', () => resolve());
      media.src = mediaSrc;
    });
  }
  if (isVideoUrl(mediaSrc)) {
    const media = document.createElement('video');
    return new Promise<void>((resolve) => {
      media.addEventListener('canplaythrough', () => {
        resolve();
      });
      media.src = mediaSrc;
      media.preload = 'auto';
    });
  }

  return Promise.resolve();
}
